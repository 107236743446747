export { registerSpanErrorInstrumentation } from './tracing/errors.js';
export { getCapturedScopesOnSpan, setCapturedScopesOnSpan } from './tracing/utils.js';
export { TRACING_DEFAULTS, startIdleSpan } from './tracing/idleSpan.js';
export { SentrySpan } from './tracing/sentrySpan.js';
export { SentryNonRecordingSpan } from './tracing/sentryNonRecordingSpan.js';
export { SPAN_STATUS_ERROR, SPAN_STATUS_OK, SPAN_STATUS_UNSET, getSpanStatusFromHttpCode, setHttpStatus } from './tracing/spanstatus.js';
export { continueTrace, startInactiveSpan, startNewTrace, startSpan, startSpanManual, suppressTracing, withActiveSpan } from './tracing/trace.js';
export { getDynamicSamplingContextFromClient, getDynamicSamplingContextFromScope, getDynamicSamplingContextFromSpan, spanToBaggageHeader } from './tracing/dynamicSamplingContext.js';
export { setMeasurement, timedEventsToMeasurements } from './tracing/measurement.js';
export { sampleSpan } from './tracing/sampling.js';
export { logSpanEnd, logSpanStart } from './tracing/logSpans.js';
export { SEMANTIC_ATTRIBUTE_CACHE_HIT, SEMANTIC_ATTRIBUTE_CACHE_ITEM_SIZE, SEMANTIC_ATTRIBUTE_CACHE_KEY, SEMANTIC_ATTRIBUTE_EXCLUSIVE_TIME, SEMANTIC_ATTRIBUTE_HTTP_REQUEST_METHOD, SEMANTIC_ATTRIBUTE_PROFILE_ID, SEMANTIC_ATTRIBUTE_SENTRY_CUSTOM_SPAN_NAME, SEMANTIC_ATTRIBUTE_SENTRY_IDLE_SPAN_FINISH_REASON, SEMANTIC_ATTRIBUTE_SENTRY_MEASUREMENT_UNIT, SEMANTIC_ATTRIBUTE_SENTRY_MEASUREMENT_VALUE, SEMANTIC_ATTRIBUTE_SENTRY_OP, SEMANTIC_ATTRIBUTE_SENTRY_ORIGIN, SEMANTIC_ATTRIBUTE_SENTRY_SAMPLE_RATE, SEMANTIC_ATTRIBUTE_SENTRY_SOURCE, SEMANTIC_ATTRIBUTE_URL_FULL } from './semanticAttributes.js';
export { createEventEnvelope, createSessionEnvelope, createSpanEnvelope } from './envelope.js';
export { addEventProcessor, captureCheckIn, captureEvent, captureException, captureMessage, captureSession, close, endSession, flush, isEnabled, isInitialized, lastEventId, setContext, setExtra, setExtras, setTag, setTags, setUser, startSession, withMonitor } from './exports.js';
export { getClient, getCurrentScope, getGlobalScope, getIsolationScope, getTraceContextFromScope, withIsolationScope, withScope } from './currentScopes.js';
export { getDefaultCurrentScope, getDefaultIsolationScope } from './defaultScopes.js';
export { setAsyncContextStrategy } from './asyncContext/index.js';
export { getGlobalSingleton, getMainCarrier } from './carrier.js';
export { closeSession, makeSession, updateSession } from './session.js';
export { Scope } from './scope.js';
export { notifyEventProcessors } from './eventProcessors.js';
export { getEnvelopeEndpointWithUrlEncodedAuth, getReportDialogEndpoint } from './api.js';
export { BaseClient, Client } from './client.js';
export { ServerRuntimeClient } from './server-runtime-client.js';
export { initAndBind, setCurrentClient } from './sdk.js';
export { createTransport } from './transports/base.js';
export { makeOfflineTransport } from './transports/offline.js';
export { makeMultiplexedTransport } from './transports/multiplexed.js';
export { addIntegration, defineIntegration, getIntegrationsToSetup } from './integration.js';
export { applyScopeDataToEvent, mergeScopeData } from './utils/applyScopeDataToEvent.js';
export { prepareEvent } from './utils/prepareEvent.js';
export { createCheckInEnvelope } from './checkin.js';
export { hasSpansEnabled, hasTracingEnabled } from './utils/hasSpansEnabled.js';
export { isSentryRequestUrl } from './utils/isSentryRequestUrl.js';
export { handleCallbackErrors } from './utils/handleCallbackErrors.js';
export { parameterize } from './utils/parameterize.js';
export { addAutoIpAddressToSession, addAutoIpAddressToUser } from './utils/ipAddress.js';
export { addChildSpanToSpan, getActiveSpan, getRootSpan, getSpanDescendants, getStatusMessage, spanIsSampled, spanTimeInputToSeconds, spanToJSON, spanToTraceContext, spanToTraceHeader, updateSpanName } from './utils/spanUtils.js';
export { parseSampleRate } from './utils/parseSampleRate.js';
export { applySdkMetadata } from './utils/sdkMetadata.js';
export { getTraceData } from './utils/traceData.js';
export { getTraceMetaTags } from './utils/meta.js';
export { extractQueryParamsFromUrl, headersToDict, httpRequestToRequestData, winterCGHeadersToDict, winterCGRequestToRequestData } from './utils/request.js';
export { DEFAULT_ENVIRONMENT } from './constants.js';
export { addBreadcrumb } from './breadcrumbs.js';
export { functionToStringIntegration } from './integrations/functiontostring.js';
export { inboundFiltersIntegration } from './integrations/inboundfilters.js';
export { linkedErrorsIntegration } from './integrations/linkederrors.js';
export { moduleMetadataIntegration } from './integrations/metadata.js';
export { requestDataIntegration } from './integrations/requestdata.js';
export { captureConsoleIntegration } from './integrations/captureconsole.js';
export { dedupeIntegration } from './integrations/dedupe.js';
export { extraErrorDataIntegration } from './integrations/extraerrordata.js';
export { rewriteFramesIntegration } from './integrations/rewriteframes.js';
export { zodErrorsIntegration } from './integrations/zoderrors.js';
export { thirdPartyErrorFilterIntegration } from './integrations/third-party-errors-filter.js';
export { profiler } from './profiling.js';
export { instrumentFetchRequest } from './fetch.js';
export { trpcMiddleware } from './trpc.js';
export { captureFeedback } from './feedback.js';
export { applyAggregateErrorsToEvent } from './utils-hoist/aggregate-errors.js';
export { getBreadcrumbLogLevelFromHttpStatusCode } from './utils-hoist/breadcrumb-log-level.js';
export { getComponentName, getLocationHref, htmlTreeAsString } from './utils-hoist/browser.js';
export { dsnFromString, dsnToString, makeDsn } from './utils-hoist/dsn.js';
export { SentryError } from './utils-hoist/error.js';
export { GLOBAL_OBJ } from './utils-hoist/worldwide.js';
export { addConsoleInstrumentationHandler } from './utils-hoist/instrument/console.js';
export { addFetchEndInstrumentationHandler, addFetchInstrumentationHandler } from './utils-hoist/instrument/fetch.js';
export { addGlobalErrorInstrumentationHandler } from './utils-hoist/instrument/globalError.js';
export { addGlobalUnhandledRejectionInstrumentationHandler } from './utils-hoist/instrument/globalUnhandledRejection.js';
export { addHandler, maybeInstrument, resetInstrumentationHandlers, triggerHandlers } from './utils-hoist/instrument/handlers.js';
export { isDOMError, isDOMException, isElement, isError, isErrorEvent, isEvent, isInstanceOf, isParameterizedString, isPlainObject, isPrimitive, isRegExp, isString, isSyntheticEvent, isThenable, isVueViewModel } from './utils-hoist/is.js';
export { isBrowser } from './utils-hoist/isBrowser.js';
export { CONSOLE_LEVELS, consoleSandbox, logger, originalConsoleMethods } from './utils-hoist/logger.js';
export { addContextToFrame, addExceptionMechanism, addExceptionTypeValue, checkOrSetAlreadyCaught, getEventDescription, parseSemver, uuid4 } from './utils-hoist/misc.js';
export { isNodeEnv, loadModule } from './utils-hoist/node.js';
export { normalize, normalizeToSize, normalizeUrlToBase } from './utils-hoist/normalize.js';
export { addNonEnumerableProperty, convertToPlainObject, dropUndefinedKeys, extractExceptionKeysForMessage, fill, getOriginalFunction, markFunctionWrapped, objectify } from './utils-hoist/object.js';
export { basename, dirname, isAbsolute, join, normalizePath, relative, resolve } from './utils-hoist/path.js';
export { makePromiseBuffer } from './utils-hoist/promisebuffer.js';
export { severityLevelFromString } from './utils-hoist/severity.js';
export { UNKNOWN_FUNCTION, createStackParser, getFramesFromEvent, getFunctionName, stackParserFromStackParserOptions, stripSentryFramesAndReverse } from './utils-hoist/stacktrace.js';
export { filenameIsInApp, node, nodeStackLineParser } from './utils-hoist/node-stack-trace.js';
export { isMatchingPattern, safeJoin, snipLine, stringMatchesSomePattern, truncate } from './utils-hoist/string.js';
export { isNativeFunction, supportsDOMError, supportsDOMException, supportsErrorEvent, supportsFetch, supportsHistory, supportsNativeFetch, supportsReferrerPolicy, supportsReportingObserver } from './utils-hoist/supports.js';
export { SyncPromise, rejectedSyncPromise, resolvedSyncPromise } from './utils-hoist/syncpromise.js';
export { browserPerformanceTimeOrigin, dateTimestampInSeconds, timestampInSeconds } from './utils-hoist/time.js';
export { TRACEPARENT_REGEXP, extractTraceparentData, generateSentryTraceHeader, propagationContextFromHeaders } from './utils-hoist/tracing.js';
export { getSDKSource, isBrowserBundle } from './utils-hoist/env.js';
export { addItemToEnvelope, createAttachmentEnvelopeItem, createEnvelope, createEventEnvelopeHeaders, createSpanEnvelopeItem, envelopeContainsItemType, envelopeItemTypeToDataCategory, forEachEnvelopeItem, getSdkMetadataForEnvelopeHeader, parseEnvelope, serializeEnvelope } from './utils-hoist/envelope.js';
export { createClientReportEnvelope } from './utils-hoist/clientreport.js';
export { DEFAULT_RETRY_AFTER, disabledUntil, isRateLimited, parseRetryAfterHeader, updateRateLimits } from './utils-hoist/ratelimit.js';
export { MAX_BAGGAGE_STRING_LENGTH, SENTRY_BAGGAGE_KEY_PREFIX, SENTRY_BAGGAGE_KEY_PREFIX_REGEX, baggageHeaderToDynamicSamplingContext, dynamicSamplingContextToSentryBaggageHeader, parseBaggageHeader } from './utils-hoist/baggage.js';
export { getSanitizedUrlString, parseUrl, stripUrlQueryAndFragment } from './utils-hoist/url.js';
export { eventFromMessage, eventFromUnknownInput, exceptionFromError, parseStackFrames } from './utils-hoist/eventbuilder.js';
export { callFrameToStackFrame, watchdogTimer } from './utils-hoist/anr.js';
export { LRUMap } from './utils-hoist/lru.js';
export { generateSpanId, generateTraceId } from './utils-hoist/propagationContext.js';
export { vercelWaitUntil } from './utils-hoist/vercelWaitUntil.js';
export { SDK_VERSION } from './utils-hoist/version.js';
export { getDebugImagesForResources, getFilenameToDebugIdMap } from './utils-hoist/debug-ids.js';
export { escapeStringForRegex } from './utils-hoist/vendor/escapeStringForRegex.js';
